"use client";
import * as React from "react";
import { HeroComponent as HeroProps } from "movestic-blocks/components/__generated__/graphql";
import { PrimaryLinkButton, SecondaryLinkButton } from "movestic-core/tailwind/Buttons";
import { H1Hero, TextLarge } from "movestic-core/tailwind/Texts";
import { icons } from "movestic-core/images";
import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";

interface ExtendedHeroProps extends HeroProps {
    body?: any;
    isArticlePage?: boolean;
}

const longwordHyphenation = [
    "försäkring",
    "kalkylator",
    "pension",
    "planera",
    "utbetalning",
    "sparande",
    "rådgivn",
    "lösning",
    "skydd",
    "förmån",
    "villkor",
];
const regex = new RegExp(`(${longwordHyphenation.join("|")})`, "g");

export const HeroBlock: React.FunctionComponent<ExtendedHeroProps> = ({
    heading,
    image,
    video,
    text,
    buttons,
    logo,
    body,
    isArticlePage,
    minHeight,
}) => {
    const backgroundLogo = logo === "white" ? icons.backgroundLogoWhite : logo === "noLogo" ? "" : icons.backgroundLogo;
    let minimumHeight = "";
    const heroImage: "video" | "image" | "none" = video?.filename ? "video" : image.filename ? "image" : "none";

    switch (minHeight) {
        case "320px":
            minimumHeight = "md:min-h-[320px]";
            break;
        default:
            minimumHeight = "md:min-h-[528px]";
            break;
    }

    let hasContent = false;

    if (body && body.content && Array.isArray(body.content)) {
        hasContent = body.content.some((item) => item.content && item.content.length > 0);
    }

    const htmlHeading = heading.replace(regex, "&shy;$1").replace(" &shy;", " ");
    const backgroundLogoStyle = backgroundLogo ? { backgroundImage: `url(${backgroundLogo})` } : {};
    const imageFormat = `${image.filename}/m/552x314/smart`;
    const imageFormatX2 = `${image.filename}/m/1104x628/smart`;

    return (
        <section
            className={`relative flex px-4 md:px-0 bg-cover ${isArticlePage ? "md:min-h-[320px]" : `${minimumHeight} bg-cherryMist`} overflow-hidden`}
        >
            <div
                className={`container relative flex flex-col items-center w-full ${isArticlePage ? "" : "md:px-8"} py-8 md:gap-8 md:flex-row`}
            >
                {backgroundLogo && (
                    <div
                        className={`absolute -right-60 md:-top-28 h-[640px] w-full bg-right-top bg-no-repeat hidden md:block pointer-events-none bg-[length:65%]`}
                        style={backgroundLogoStyle}
                    />
                )}
                <div
                    className={`flex flex-col items-start gap-4 py-8 z-[1] ${!isArticlePage ? " md:basis-1/2" : "md:basis-2/3"} text-lightBlack basis-full`}
                >
                    <H1Hero dangerouslySetInnerHTML={{ __html: htmlHeading }} />
                    {hasContent && <RichtextContent richContent={body} />}
                    {text && (
                        <TextLarge className={`${buttons?.length === 0 ? "mb-10 md:mb-0" : ""}`}>{text}</TextLarge>
                    )}
                    {buttons?.length > 0 && (
                        <div className="flex flex-col w-full gap-4 md:flex-row">
                            {buttons?.map((button, index: number) => {
                                const Button = button.type === "primary" ? PrimaryLinkButton : SecondaryLinkButton;
                                const link = getLink(button.link);
                                return (
                                    <Button key={index} href={link.linkUrl} target={link.linkTarget}>
                                        {button.text}
                                    </Button>
                                );
                            })}
                        </div>
                    )}
                </div>
                {heroImage === "video" && (
                    <video
                        playsInline
                        autoPlay
                        muted
                        loop
                        width={552}
                        height={314}
                        preload="none"
                        aria-label="Video player"
                        onContextMenu={(e) => e.preventDefault()}
                        className={`rounded-lg z-10`}
                    >
                        <source src={video.filename} />
                        {image.filename && (
                            <img
                                src={`${imageFormat}`}
                                alt={image?.alt}
                                width={552}
                                height={314}
                                className={`rounded-lg`}
                                fetchPriority="high"
                            />
                        )}
                    </video>
                )}
                {heroImage === "image" && (
                    <div
                        className={`z-10 flex self-center justify-end ${isArticlePage ? "md:basis-1/3" : "md:basis-1/2"}`}
                    >
                        <img
                            src={`${imageFormat}`}
                            srcSet={`${imageFormatX2} 2x`}
                            alt={image?.alt}
                            width={552}
                            height={314}
                            className={`rounded-lg`}
                            fetchPriority="high"
                        />
                    </div>
                )}
            </div>
        </section>
    );
};
